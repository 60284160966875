import useAxios from '../helper/api';
import { serviceDomain } from '../../config/app.config';
import { Settings } from '../helper/type';

const { settings: settingsUrl } = serviceDomain;

type UpdateSettingsPayload = Partial<Settings>;

export const useSettingsService = () => {
  const axios = useAxios();

  const getSettings = () =>
    axios.get(`${settingsUrl}/all`, {
      requestKey: 'settings/all',
      isInterruptive: false,
      withCredentials: true,
      checkTokenValidity: true,
    });

  const updateSettings = (payload: UpdateSettingsPayload) =>
    axios.patch(`${settingsUrl}/update`, payload, {
      requestKey: 'settings/update',
      isInterruptive: true,
      withCredentials: true,
      checkTokenValidity: true,
      feedback: {
        loading: 'Updating your settings',
        success: 'Settings updated',
        failure: true,
      },
    });

  const broadcastTelegram = (imkey: string, message: string) =>
    axios.post(
      `${settingsUrl}/broadcast/telegram`,
      { imkey, message },
      {
        requestKey: 'settings/broadcast/telegram',
        isInterruptive: true,
        withCredentials: false,
        feedback: {
          loading: 'Sending broadcast message',
          success: 'Message sent',
          failure: true,
        },
      }
    );

  return {
    getSettings,
    updateSettings,
    broadcastTelegram,
  };
};

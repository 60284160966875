import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import { TALENT_ROUTES } from './constant';
import { featureToggle } from '../../config/app.config';
import ApplicationPage from '../page/Talent/Application/Application';
import JobDirectoryPage from '../page/Job/Directory/Directory';
import TalentFormPage from '../page/Talent/Form/Form';
import ChangePasswordPage from '../page/Auth/ChangePassword/ChangePassword';
import SettingsPage from '../page/Talent/Settings/Settings';
import PromptInstallPage from '../page/PromptInstall/PromptInstall';
import PromptNotificationPage from '../page/PromptNotification/PromptNotification';
import TalentActivityPage from '../page/Talent/Activity/Activity';
import TalentAgendaPage from '../page/Talent/Agenda/Agenda';
import TalentRatingsPage from '../page/Talent/Ratings/Ratings';
import ViewCommentsPage from '../page/Talent/Ratings/ViewComments';
import InvoiceDetailsPage from '../page/Talent/Invoice/InvoiceDetails';
import SubmitAllowance from '../page/Talent/Invoice/SubmitAllowance/SubmitAllowance';

const ratingRoutes: RouteProps[] = [
  {
    path: '/talent/ratings/role/:roleId/comments',
    component: ViewCommentsPage,
  },
  {
    path: '/talent/ratings',
    component: TalentRatingsPage,
  },
];

const invoiceRoutes: RouteProps[] = [
  {
    path: '/talent/project/:projectId/invoice/:invoiceId',
    component: InvoiceDetailsPage,
  },
  {
    path: '/talent/invoice/:invoiceId/allowance/:mode(new|edit|view)',
    component: SubmitAllowance,
  },
];

const talentRoutes: RouteProps[] = [
  ...(featureToggle.rating ? ratingRoutes : []),
  ...(featureToggle.invoice ? invoiceRoutes : []),

  {
    path: TALENT_ROUTES.AGENDA,
    component: TalentAgendaPage,
  },
  {
    path: '/talent/jobs',
    component: JobDirectoryPage,
  },
  {
    path: '/talent/applications',
    component: ApplicationPage,
  },
  {
    path: '/talent/profile',
    component: TalentFormPage,
  },
  {
    path: '/talent/change-password',
    component: ChangePasswordPage,
  },
  {
    path: '/talent/settings',
    component: SettingsPage,
  },
  {
    path: '/talent/prompt-install',
    component: PromptInstallPage,
  },
  {
    path: '/talent/prompt-notification',
    component: PromptNotificationPage,
  },
  {
    path: '/talent/activity/:activityId',
    component: TalentActivityPage,
  },
  {
    path: '/talent',
    exact: true,
    render: () => <Redirect to='/talent/jobs' />,
  },
];

export default talentRoutes;

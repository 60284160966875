import axios from 'axios';
import { serviceDomain } from '../../config/app.config';

const { analytic: analyticUrl } = serviceDomain;

export enum IdentityTraits {
  TYPE = 'type',
}

export const identify = async (
  userId: string,
  traits?: Record<string, string | number>
) => {
  const timestamp = new Date().getTime() / 1000;
  return axios.post(
    `${analyticUrl}/i`,
    {
      type: 'identify',
      userId,
      traits,
      timestamp,
    },
    {
      withCredentials: true,
    }
  );
};

export const trackEvent = async (
  event: string,
  properties?: Record<string, string | number>
) => {
  const timestamp = new Date().getTime() / 1000;
  return axios.post(
    `${analyticUrl}/t`,
    {
      type: 'event',
      event,
      properties,
      timestamp,
    },
    {
      withCredentials: true,
    }
  );
};

export const trackPage = async (
  name: string,
  properties?: Record<string, string | number>
) => {
  const timestamp = new Date().getTime() / 1000;
  return axios.post(
    `${analyticUrl}/t`,
    {
      type: 'page',
      name,
      properties,
      timestamp,
    },
    {
      withCredentials: true,
    }
  );
};
